import React, { Suspense, lazy, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import Loader from '@appchoose/loader';
import { NotifyUpdate } from '@appchoose/notify';
import Toast from '@appchoose/toast';
import { TooltipProvider } from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { t } from 'i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { AuthProvider, RequireAuth, RequireAuthorization } from './api/auth';
import './api/sentry';
import { CommandMenu } from './components/command/command-menu';
import { LayoutWithNavbar } from './components/layout-with-navbar/layout-with-navbar';
import './lang/i18n';
import { BrandPage } from './pages/brand-page/brand-page';
import { LoginPage } from './pages/login-page/login-page';
import { UnauthorizedPage } from './pages/unauthorized-page/unauthorized-page';
import { UserRole } from './utils/role';

const CalendarPage = lazy(() => import('./pages/calendar-page/calendar-page'));
const DiscountPage = lazy(() => import('./pages/discount-page/discount-page'));
const MarketingPage = lazy(
  () => import('./pages/marketing-page/marketing-page')
);
const NotificationPage = lazy(
  () => import('./pages/notification-page/notification-page')
);
const SalePage = lazy(() => import('./pages/sale-page/sale-page'));
const SalesPage = lazy(() => import('./pages/sales-page/sales-page'));
const SetupPage = lazy(() => import('./pages/setup-page/setup-page'));
const AiToolsPage = lazy(() => import('./pages/ai-tools-page/ai-tools-page'));

// interval between each call to the Service Worker
const intervalMS = 2 * 60 * 1000;

const queryClient = new QueryClient();

export const App: React.FC = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(registration) {
      return (
        registration &&
        setInterval(() => {
          if (registration.installing || !navigator) return;

          if ('connection' in navigator && !navigator.onLine) return;

          registration.update();
        }, intervalMS)
      );
    },
    onRegisterError(error) {
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    document.addEventListener('wheel', () => {
      const numberInput = document.activeElement as HTMLInputElement;
      if (numberInput.type === 'number') {
        numberInput.blur();
      }
    });
    document.addEventListener('keydown', (event) => {
      const numberInput = document.activeElement as HTMLInputElement;
      if (
        numberInput.type === 'number' &&
        (event.key === 'e' || event.key === 'E')
      ) {
        event.preventDefault();
      }
    });
  }, []);

  return (
    <Sentry.ErrorBoundary>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <TooltipProvider>
            <BrowserRouter>
              <DndProvider backend={HTML5Backend}>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/calendar"
                    element={
                      <RequireAuth>
                        <RequireAuthorization
                          onlyAdmin={false}
                          requireRole={[UserRole.SALES, UserRole.CALENDAR]}
                        >
                          <Suspense
                            fallback={
                              <div className="flex h-full items-center justify-center">
                                <Loader className="size-8" />
                              </div>
                            }
                          >
                            <CalendarPage />
                          </Suspense>
                        </RequireAuthorization>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/aitools"
                    element={
                      <RequireAuth>
                        <RequireAuthorization
                          onlyAdmin={false}
                          requireRole={[UserRole.SALES]}
                        >
                          <Suspense
                            fallback={
                              <div className="flex h-full items-center justify-center">
                                <Loader className="size-8" />
                              </div>
                            }
                          >
                            <AiToolsPage />
                          </Suspense>
                        </RequireAuthorization>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/setup/:saleId"
                    element={
                      <RequireAuth>
                        <RequireAuthorization
                          onlyAdmin={false}
                          requireRole={[UserRole.SALES]}
                        >
                          <Suspense
                            fallback={
                              <div className="flex h-full items-center justify-center">
                                <Loader className="size-8" />
                              </div>
                            }
                          >
                            <SetupPage />
                          </Suspense>
                        </RequireAuthorization>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <RequireAuth>
                        <LayoutWithNavbar>
                          <Routes>
                            <Route
                              path="/"
                              element={<Navigate to={'/ventes'} />}
                            />
                            <Route
                              path="/ventes"
                              element={
                                <RequireAuthorization
                                  onlyAdmin={false}
                                  requireRole={[UserRole.SALES]}
                                >
                                  <Suspense
                                    fallback={
                                      <div className="flex h-dvh items-center justify-center">
                                        <Loader className="size-8" />
                                      </div>
                                    }
                                  >
                                    <SalesPage />
                                  </Suspense>
                                </RequireAuthorization>
                              }
                            />
                            <Route path="/vente">
                              <Route
                                path=":saleId"
                                element={
                                  <RequireAuthorization
                                    onlyAdmin={false}
                                    requireRole={[UserRole.SALES]}
                                  >
                                    <Suspense
                                      fallback={
                                        <div className="flex h-dvh items-center justify-center">
                                          <Loader className="size-8" />
                                        </div>
                                      }
                                    >
                                      <SalePage />
                                    </Suspense>
                                  </RequireAuthorization>
                                }
                              />
                            </Route>
                            <Route
                              path="/notifications"
                              element={
                                <RequireAuthorization
                                  onlyAdmin={false}
                                  requireRole={[UserRole.NOTIFICATIONS]}
                                >
                                  <Suspense
                                    fallback={
                                      <div className="flex h-dvh items-center justify-center">
                                        <Loader className="size-8" />
                                      </div>
                                    }
                                  >
                                    <NotificationPage />
                                  </Suspense>
                                </RequireAuthorization>
                              }
                            />
                            <Route
                              path="/marketing"
                              element={
                                <RequireAuthorization
                                  onlyAdmin={false}
                                  requireRole={[UserRole.NOTIFICATIONS]}
                                >
                                  <Suspense
                                    fallback={
                                      <div className="flex h-dvh items-center justify-center">
                                        <Loader className="size-8" />
                                      </div>
                                    }
                                  >
                                    <MarketingPage />
                                  </Suspense>
                                </RequireAuthorization>
                              }
                            />
                            <Route
                              path="/discount"
                              element={
                                <RequireAuthorization
                                  onlyAdmin={false}
                                  requireRole={[UserRole.DISCOUNTS]}
                                >
                                  <Suspense
                                    fallback={
                                      <div className="flex h-dvh items-center justify-center">
                                        <Loader className="size-8" />
                                      </div>
                                    }
                                  >
                                    <DiscountPage />
                                  </Suspense>
                                </RequireAuthorization>
                              }
                            />
                            <Route path="/marque">
                              <Route
                                path=":brandId"
                                element={
                                  <RequireAuthorization
                                    onlyAdmin={false}
                                    requireRole={[UserRole.SALES]}
                                  >
                                    <Suspense
                                      fallback={
                                        <div className="flex h-dvh items-center justify-center">
                                          <Loader className="size-8" />
                                        </div>
                                      }
                                    >
                                      <BrandPage />
                                    </Suspense>
                                  </RequireAuthorization>
                                }
                              />
                            </Route>
                            <Route
                              path="/unauthorized"
                              element={<UnauthorizedPage />}
                            />
                          </Routes>
                        </LayoutWithNavbar>
                      </RequireAuth>
                    }
                  />
                </Routes>
              </DndProvider>
              <CommandMenu />
            </BrowserRouter>

            <ReactQueryDevtools initialIsOpen={false} />
          </TooltipProvider>
        </QueryClientProvider>
      </AuthProvider>
      <Toast />
      {needRefresh && (
        <NotifyUpdate
          title={t('update.new_version')}
          description={t('update.refresh_page')}
          onClick={() => {
            updateServiceWorker();
            setNeedRefresh(false);
          }}
        />
      )}
    </Sentry.ErrorBoundary>
  );
};
