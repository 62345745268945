import { useSetAtom } from 'jotai';

import { crawlingProductsState } from '../states/crawlingProductState';

const useRenameColumn = () => {
  const setCrawlingProducts = useSetAtom(crawlingProductsState);

  const modifyCustomFieldName = (index: number, newName: string) => {
    return () => {
      setCrawlingProducts((previous) => {
        if (!previous) return previous;

        const updatedProducts = structuredClone(previous);

        updatedProducts.products = updatedProducts.products.map((product) => {
          if (!product.customFields?.[index]) {
            return product;
          }

          product.customFields[index].name = newName;

          if (!product._subRows) {
            return product;
          }

          product._subRows = product._subRows.map((subRow) => {
            if (!subRow.customFields?.[index]) {
              return subRow;
            }
            subRow.customFields[index].name = newName;

            return subRow;
          });

          return product;
        });

        return updatedProducts;
      });
    };
  };

  return {
    modifyCustomFieldName,
  };
};

export default useRenameColumn;
