import { RudderAnalytics } from '@rudderstack/analytics-js/bundled';
import type { Metric } from 'web-vitals';

const analytics = new RudderAnalytics();

export const initializeAnalytics = () => {
  analytics.load(
    import.meta.env.REACT_APP_RUDDERSTACK_API_KEY as string,
    import.meta.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL as string,
    {
      integrations: { All: true },
    }
  );
};

export const setUserId = (userId: string) => {
  analytics.identify(userId);
};

export const pageView = (name: string) => {
  analytics.page(name, {
    path: window.location.pathname,
  });
};

export const track = (
  eventType: string,
  eventProperties: Record<string, string | number>
) => {
  analytics.track(eventType, eventProperties);
};

export const sendToAnalytics = ({ name, delta, value, id }: Metric) => {
  analytics.track(name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
};
