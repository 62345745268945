import type { RowSelectionState } from '@tanstack/react-table';
import { atom, useSetAtom } from 'jotai';

import type { CrawlingProduct } from '../types';

export const rowSelectionState = atom<RowSelectionState>({});

export const selectedRowsState = atom<CrawlingProduct[]>([]);

export const useResetSelection = () => {
  const setSelectedRows = useSetAtom(selectedRowsState);
  const setRowSelection = useSetAtom(rowSelectionState);

  const resetSelection = () => {
    // On met à jour le state de la sélection Tanstack en dehors de la logique du tableau
    setRowSelection({});
    // On doit donc aussi mettre à jour manuellement le state de la sélection de produits
    setSelectedRows([]);
  };

  return { resetSelection };
};

export const contextRowSelectionState = atom<string | undefined>(undefined);

export const contextSelectedRowState = atom<CrawlingProduct | undefined>(
  undefined
);

export const useResetContextSelection = () => {
  const setContextSelectedRow = useSetAtom(contextSelectedRowState);
  const setContextRowSelection = useSetAtom(contextRowSelectionState);

  const resetContextSelection = () => {
    setContextSelectedRow(undefined);
    setContextRowSelection(undefined);
  };

  return { resetContextSelection };
};
