import { atom } from 'jotai';

import type { CrawlingData } from '../types';
import { CrawlingProductStatus } from '../types';

export const crawlingProductsState = atom<CrawlingData | undefined>(undefined);

export const productsProcessedCountState = atom((get) => {
  const crawlingProducts = get(crawlingProductsState);

  return (
    crawlingProducts?.products.filter(
      (entry) =>
        entry._status === CrawlingProductStatus.SUCCESS ||
        entry._status === CrawlingProductStatus.ERROR
    ) ?? []
  ).length;
});

export const productsProcessedErrorCountState = atom((get) => {
  const crawlingProducts = get(crawlingProductsState);

  return (
    crawlingProducts?.products.filter(
      (entry) => entry._status === CrawlingProductStatus.ERROR
    ) ?? []
  ).length;
});

export const productsProcessingState = atom((get) => {
  const crawlingProducts = get(crawlingProductsState);

  return (
    (
      crawlingProducts?.products.filter(
        (entry) => entry._status === CrawlingProductStatus.IN_PROGRESS
      ) ?? []
    ).length !== 0
  );
});

export const productsProcessableState = atom((get) => {
  const crawlingProducts = get(crawlingProductsState);

  return (
    crawlingProducts?.products.filter(
      (entry) => entry._status !== CrawlingProductStatus.NOT_STARTED
    ) ?? []
  ).length;
});
