import { atom } from 'jotai';

export enum Pages {
  Home = 'home',
  SearchBrand = 'search-brand',
  SearchSale = 'search-sale',
  SetupProductsFormatDescription = 'setup-products-format-descriptions',
  SetProductCategory = 'set-product-category',
  RenameCustomFieldColumn = 'rename-custom-field-column',
  RenameCustomFieldInput = 'rename-custom-field-input',
  SetupProducts = 'setup-products',
}

export type Page = {
  page: Pages;
  placeholder: string;
  badge?: string;
};

export const pagesCommandMenuState = atom<Pages[]>([Pages.Home]);
export const isOpenCommandMenuState = atom<boolean>(false);
export const isSetupProductsActiveCommandMenuState = atom<boolean>(false);
export const selectedCustomFieldNameState = atom<string | null>(null);
