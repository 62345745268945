export const uniqBy = <T>(a: T[], key: (obj: T) => unknown) => {
  const seen = new Set();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

export const equals = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return !arr1.some((value, index) => value !== arr2[index]);
};

export const containsDifferentValues = (
  a: (string | number | null | undefined)[]
) => {
  return new Set(a).size !== 1;
};

export const containsAtLeastOneValue = (
  a: (string | number | null | undefined)[]
) => {
  return a.some((shopify_variant_id) => shopify_variant_id);
};
