import { useAtomValue, useSetAtom } from 'jotai';

import { crawlingProductsState } from './states/crawlingProductState';
import {
  contextRowSelectionState,
  rowSelectionState,
} from './states/selectedRowsState';

export const useDissociateImages = () => {
  const rowSelection = useAtomValue(rowSelectionState);
  const contextRowSelection = useAtomValue(contextRowSelectionState);
  const setCrawlingProducts = useSetAtom(crawlingProductsState);

  const onDissociateImages = () => {
    const productRowIndexes =
      Object.keys(rowSelection).length > 0
        ? Object.keys(rowSelection).map((key) =>
            parseInt(key.split('.')[0] ?? '', 10)
          )
        : contextRowSelection
          ? [parseInt(contextRowSelection.split('.')[0] ?? '', 10)]
          : null;

    setCrawlingProducts((previous) => {
      if (!previous) return;

      const products = structuredClone(previous);

      if (productRowIndexes) {
        productRowIndexes.forEach((productRowIndex) => {
          if (!products.products[productRowIndex]) return;
          products.products[productRowIndex] = {
            ...products.products[productRowIndex],
            _subRows: [
              ...(products.products[productRowIndex]._subRows ?? []).map(
                (subProduct) => ({
                  ...subProduct,
                  images: [],
                })
              ),
            ],
          };
        });
      } else {
        products.products.forEach((product) => {
          product._subRows?.forEach((variant) => {
            variant.images = [];
          });
        });
      }

      return products;
    });
  };

  return onDissociateImages;
};
