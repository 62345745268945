import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Avatar from '@appchoose/avatar';
import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormCounter,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input, { InputAdornment } from '@appchoose/input';
import { ModalClose } from '@appchoose/modal';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import {
  TextEditor,
  getShortcuts,
} from '../../../components/text-editor/text-editor';
import { useStoreRegion } from '../../../hooks/use-store-region';
import { ContactRole, contacts } from '../../../types/choose-contact';
import type { BrandQuery } from '../../../types/generated';
import { useUpdateBrandMutation } from '../../../types/generated';

type BrandDetailsForm = {
  contactName: string;
  description: string;
  instagram: string;
  name: string;
};

type BrandDetailsProps = {
  brand: BrandQuery['brand'];
};

export const BrandDetails: React.FC<BrandDetailsProps> = ({ brand }) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const storeRegion = useStoreRegion();

  const { mutateAsync: mutateUpdateBrandAsync, isPending } =
    useUpdateBrandMutation();

  const form = useForm<BrandDetailsForm>({
    defaultValues: {
      description: brand?.about ?? '',
      contactName: brand?.contactName ?? '',
      instagram: brand?.instagram ?? '',
      name: brand?.name ?? '',
    },
  });

  // Reset form values when brand changes
  useEffect(() => {
    form.reset({
      description: brand?.about ?? '',
      contactName: brand?.contactName ?? '',
      instagram: brand?.instagram ?? '',
      name: brand?.name ?? '',
    });
  }, [brand, form]);

  const [sales] = useState(
    contacts
      .filter(
        (contact) => contact.role.includes(ContactRole.Sale) && contact.isActive
      )
      .toSorted((a, b) => a.firstName.localeCompare(b.firstName))
      .map((contact) => ({
        label: contact.firstName,
        value: contact.ids[0] ?? '',
        leftSection: (
          <Avatar
            src={`https://public.choose.app/avatars/80x80/${
              contact.avatar || ''
            }-80x80.png`}
            className="!h-6 !w-6"
          />
        ),
      }))
  );

  const onSubmit = async (data: BrandDetailsForm) => {
    try {
      await mutateUpdateBrandAsync({
        data: {
          brand: {
            id: brand?.id ?? '',
            about: data.description,
            contact_name: data.contactName,
            instagram: data.instagram,
            name: data.name,
          },
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['brandSuspense', { brandId: brand?.id }],
      });

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('edit');
      setSearchParams(newSearchParams);

      toast.success(t('brand_page.brand_updated'));
    } catch {
      toast.error(t('brand_page.brand_update_error'));
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => form.handleSubmit(onSubmit)(e)}
        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
      >
        <div className="flex min-h-0 flex-1 flex-col space-y-10 overflow-y-scroll p-10">
          <h2 className="text-2xl font-bold text-gray-900">
            {t('brand_page.edit_brand')}
          </h2>
          <hr />
          <FormField
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_page.brand_details_form.fields.name.label')}
                </FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'brand_page.brand_details_form.fields.name.validation_errors.required'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contactName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_page.brand_details_form.fields.contact_name.label')}
                </FormLabel>
                <Select
                  value={field.value}
                  onValueChange={(value) => field.onChange(value)}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t(
                          'sale_page.edit_sale.sale_details_form.fields.sale_by.empty_field'
                        )}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {sales.map((sale) => (
                      <SelectItem key={sale.value} value={sale.value}>
                        <div className="flex items-center space-x-2 truncate">
                          <span>{sale.leftSection}</span>
                          <span>{sale.label}</span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="instagram"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_page.brand_details_form.fields.instagram.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    {...field}
                    startAdornment={
                      <InputAdornment position="start">@</InputAdornment>
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field: { value, onChange, onBlur } }) => (
              <FormItem>
                <FormLabel>
                  {t('brand_page.brand_details_form.fields.description.label')}
                </FormLabel>
                <FormControl>
                  <TextEditor
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    shortcuts={getShortcuts(storeRegion)}
                  />
                </FormControl>
                <div className="flex items-center justify-end">
                  <FormCounter>{value?.length}/1200</FormCounter>
                </div>
              </FormItem>
            )}
          ></FormField>
        </div>
        <footer className="flex shrink-0 space-x-6 border-t border-gray-100 px-10 py-4">
          <Button type="submit" disabled={isPending}>
            {t('calendar.sale_details.edit')}
          </Button>
          <ModalClose>
            <Button
              type="button"
              className="border-none text-green-900"
              appearance="outline"
              tabIndex={-1}
            >
              {t('cancel')}
            </Button>
          </ModalClose>
        </footer>
      </form>
    </Form>
  );
};
