import { atom } from 'jotai';

import type { ConnectedUsers } from '../api/auth';

export const connectedUsersState = atom<ConnectedUsers | undefined>(
  undefined
  /*default: {
    '1': {
      user: {
        displayName: 'Mélissa Imrek',
        email: 'melissa@appchoose.io',
      },
      key: '5d695db7a0d344e080fea4058',
      page: 'vente',
    },
    '2': {
      user: {
        displayName: 'David Rouyer',
        email: 'david@appchoose.io',
      },
      key: '5d695db7a0d344e080fea4058',
      page: 'vente',
    },
    '3': {
      user: {
        displayName: 'Anne-Stéphanie Dupin',
        email: 'anne-stephanie@appchoose.io',
      },
      key: '5d695db7a0d344e080fea4058',
      page: 'vente',
    },
    '4': {
      user: {
        displayName: 'Perrine Gangloff',
        email: 'perrine@appchoose.io',
      },
      key: '5d695db7a0d344e080fea4058',
      page: 'vente',
    },
  },*/
);
