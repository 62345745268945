import { useSearchParams } from 'react-router-dom';

import { z } from 'zod';

import { StoreRegion } from '../types/generated';

const regionSchema = z.enum(['fr', 'us']);

export const useStoreRegion = () => {
  const [searchParams] = useSearchParams();

  const result = regionSchema.safeParse(searchParams.get('region'));

  return (result.data as StoreRegion | undefined) ?? StoreRegion.Fr;
};
