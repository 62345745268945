/* eslint-disable no-irregular-whitespace */
import type { RowSelectionState } from '@tanstack/react-table';
import type { ColumnDef as TanstackColumnDef } from '@tanstack/react-table';
import { atom, useSetAtom } from 'jotai';

export enum CategoryType {
  coffret = 'coffret',
  cosmetique = 'cosmetique',
  enfants = 'enfants',
  compl = 'compl',
  nourriture = 'nourriture',
  pap = 'pap',
  bijoux = 'bijoux',
  plantes = 'plantes',
  maison = 'maison',
  couture = 'couture',
}

export enum AIReworkProductStatus {
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  NOT_STARTED = 'not-started',
  SUCCESS = 'success',
}

export type AIReworkProduct = {
  // Technical data
  _status: AIReworkProductStatus;
  // Mandatory product fields
  productId: string;
  name: string;
  description: string;

  // Optionnal product fields following rework
  reworkedName?: string;
  reworkedDescription?: string;
};

export type AIReworkData = {
  saleName: string;
  products: AIReworkProduct[];
};

export type AIReworkCreateProduct = {
  id: string;
  name: string;
  description: string;
};

export type ExtendedColumnDef<T> = TanstackColumnDef<T> & {
  accessorKey: string;
  name?: string;
};

export const aiReworkProductsState = atom<AIReworkData | undefined>(undefined);

export const aiProductsProcessingState = atom((get) => {
  const aiReworkProducts = get(aiReworkProductsState);

  return (
    (
      aiReworkProducts?.products.filter(
        (entry) => entry._status === AIReworkProductStatus.IN_PROGRESS
      ) ?? []
    ).length !== 0
  );
});

export const aiProductsProcessedCountState = atom((get) => {
  const aiReworkProducts = get(aiReworkProductsState);

  return (
    aiReworkProducts?.products.filter(
      (entry) =>
        entry._status === AIReworkProductStatus.SUCCESS ||
        entry._status === AIReworkProductStatus.ERROR
    ) ?? []
  ).length;
});

export const aiProductsProcessedErrorCountState = atom((get) => {
  const aiReworkProducts = get(aiReworkProductsState);

  return (
    aiReworkProducts?.products.filter(
      (entry) => entry._status === AIReworkProductStatus.ERROR
    ) ?? []
  ).length;
});

export const aiProductsProcessableState = atom((get) => {
  const aiReworkProducts = get(aiReworkProductsState);

  return (
    aiReworkProducts?.products.filter(
      (entry) => entry._status !== AIReworkProductStatus.NOT_STARTED
    ) ?? []
  ).length;
});

export const aiRowSelectionState = atom<RowSelectionState>({});

export const aiSelectedRowsState = atom<AIReworkProduct[]>([]);

export const useResetAiSelection = () => {
  const setAiSelectedRows = useSetAtom(aiSelectedRowsState);
  const setAiRowSelection = useSetAtom(aiRowSelectionState);

  const resetSelection = () => {
    // On met à jour le state de la sélection Tanstack en dehors de la logique du tableau
    setAiRowSelection({});
    // On doit donc aussi mettre à jour manuellement le state de la sélection de produits
    setAiSelectedRows([]);
  };

  return { resetSelection };
};

type CategoryDetails = {
  label: string;
  promptFr: string;
  promptEn: string;
};

export const promptByCategory: Record<CategoryType, CategoryDetails> = {
  [CategoryType.bijoux]: {
    label: '💍 Bijoux',
    promptFr: `Fais les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d’accroche

⛓️ Matériaux :

📐 Dimensions

☁️ (uniquement si mentionné) Sans allergène.

💦 (uniquement si mentionné) Résiste à l’eau.

🧽 (uniquement si mentionné) Entretien

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Catchphrase

⛓️ Materials:

📐 Dimensions:

☁️ (only if mentioned) Allergen-free

💦 (only if mentioned) Water-resistant

🧽 (only if mentioned) Care:

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.coffret]: {
    label: '🧳 Coffret',
    promptFr: `Fais les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d'intro

👀 Le coffret contient
- Produit n°1 - XX ml (ou g).
- Produit n°2 - XX ml (ou g).
- Etc...

💖 Présentation en quelques mots du produit n°1 et ses bienfaits (si info disponible).

🌸 Présentation en quelques mots du produit n°2 et ses bienfaits (si info disponible).

🧚‍♀️ Comment les utiliser ?
- 
- 
- Etc.

1️⃣ Produit n°1
- 
- 
- Etc.

2️⃣ Produit n°2
- 
- 
- Etc.

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Introductory sentence

👀 The box contains:
- Product n°1 - XXml (or g) 
- Product n°2 - XXml (or g) 
- Etc... 

💖 Presentation in a few words of product n°1 and its benefits (if info available). 

🌸 A brief presentation of product n°2 and its benefits (if available).

🧚‍♀️ How to use them? 
- 
- 
- Etc.

1️⃣ Product n°1:
-
-
- Etc.

2️⃣ Product n°2:
- 
- 
- Etc.

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.compl]: {
    label: '💊 Complément',
    promptFr: `Fais les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d’intro 1.

🌈 Phrase d’intro 2.

🫶 Bienfaits
-
-
- Etc.

🌱 Ingrédients clés
-
-
- Etc.

💊 Contenance : nombre de pillules (ou gélules, ou comprimés, ou caspules selon l'information disponible) par boîte/pot.

🧚‍♀️ Comment les prendre ?

📅 Durée de cure idéale : (si info disponible, sinon ne rien mettre)

⚠️ Précautions d'emploi (s’il y en a, sinon ne rien mettre)
-
-
- Etc.

✅ Certifié bio (si c’est le cas).

🐰 Vegan (si c’est le cas).

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Intro sentence 1.

🌈 Intro sentence 2.

🫶 Benefits:
-
-
- Etc.

🌱 Key ingredients:
-
-
- Etc.

💊 Content: number of pills (or capsules, or tablets, or cappules according to the information available) per box/jar.

🧚‍♀️ How to take them?

📅 Ideal duration of treatment: (if information available, otherwise do not put anything)

⚠️ Precautions for use (if there are any, otherwise do not put anything):
-
-
- Etc.

✅ Certified organic (if so).

🐰 Vegan (if so).

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.cosmetique]: {
    label: '💄 Cosmétique',
    promptFr: `Fais les descriptions de ces produits en suivant le template et l'exemple suivant. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d'intro 1.

💛 Phrase d'intro 2.

👱 (uniquement si mentionné) Type de peau/cheveux :

🫶 Bienfaits
-
-
- Etc.

🌱 (uniquement si mentionné) Ingrédients clés
-
-
- Etc.

🧚 Comment l'utiliser ?
-
-
- Etc.

✅ Informations complémentaires - Certifications (si infos à disposition)

⚠ (uniquement si mentionné) Précautions d'emploi :
-
-
- Etc.

⚖️ (uniquement si mentionné) Contenance :

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)
`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Catchphrase 1

💛 Catchphrase 2

👱 (only if mentioned) Skin/Hair type:

🫶 Benefits:
-
-
- Etc.

🌱 (only if mentioned) Key ingredients:
-
-
- Etc.

🧚‍♀️ How to use:
-
-
- Etc.

✅ Additional information - Certifications (if information available)

⚠ (only if mentioned) Precautions for use:
-
-
- Etc.

⚖️ (only if mentioned) Volume:

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.couture]: {
    label: '🧶 Couture',
    promptFr: `Fais les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

🧶 Phrase d’accroche

🫶 Description du vêtement

🪡 Détails (ou contenu du pack)
-
-
- Etc.

📐 Tailles disponibles sur le patron :

🥇 Niveau de la réalisation :

⏲️ Temps de réalisation :

✂ Fournitures nécessaires
-
-
- Etc.

🧵 Tissus conseillés
-
-
- Etc.

📏 Métrage de tissu recommandé (si donné par la marque) :

⚠ Information importante complémentaire (Par exemple s’il s’agit de patron PDF).

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

🧶 Catchphrase

🫶 Description of the garment

🪡 Details (or contents of the pack):
-
-
- Etc.

📐 Sizes available on the pattern:

🥇 Level of production:

⏲️ Production time:

✂ Necessary supplies:
-
-
- Etc.

🧵 Recommended fabrics:
-
-
- Etc.

📏 Recommended fabric yardage (if given by the brand):

⚠ Additional important information (For example if it is a PDF pattern).

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.enfants]: {
    label: '🧸 Enfants',
    promptFr: `Retravaille les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

🐣 Phrase d’intro 1 

🧸 Phrase d’intro 2

🍼 Phrase d’intro 3

👶 Âge recommandé : 

🪡 Détails : 

🧵 Composition : 

📐 Dimensions : 

🧺 Entretien : 

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

🐣 Intro sentence 1

🧸 Intro sentence 2

🍼 Intro sentence 3

👶 Recommended age:

🪡 Details:

🧵 Material:

📐 Dimensions:

🧺 Care:

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.maison]: {
    label: '🏠 Maison',
    promptFr: `Retravaille les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d’intro 1

🤍 Phrase d’intro 2

🔎 Matériau(x)
-
-
- Etc.

📐 Dimensions 
-
-
- Etc.

🧽 Entretien
-
-
- Etc.

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Catchphrase 1

🤍 Intro sentence 2

🔎 Materials:
-
-
- Etc.

📐 Dimensions:
-
-
- Etc.

🧽 Care:
-
-
- Etc.

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.nourriture]: {
    label: '🥕 Nourriture',
    promptFr: `Fais les descriptions de ces produits en suivant le template et l’exemple suivant. Si tu ne trouves pas l’information, n’invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d’orthographe si besoin.
✨ Phrase d’intro 1

🤍 Phrase d’intro 2

⚖️ Poids :

🗓️ DLC :

🔎 Ingrédients :

⚠️ Allergènes :

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Intro sentence 1

🤍 Intro sentence 2

⚖️ Weight:

🗓️ Peremption Date:

🔎 Ingredients:

⚠️ Allergens:

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.pap]: {
    label: '👖 Prêt-à-Porter',
    promptFr: `Fais les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

✨ Phrase d’accroche

🪡 Détails
-
-
- Etc.

🧵 Composition
-
-
- Etc.

📐 Guide des tailles
-
-
- Etc.

🧺 Entretien
-
-
- Etc.

📍 (uniquement si mentionné) Pays de Fabrication (mettez l'émoji du drapeau concernant le pays mentionné au début de la phrase)`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

✨ Catchphrase

🪡 Details:
-
-
- Etc.

🧵 Material:
-
-
- Etc.

📐 Size guide:
-
-
- Etc.

🧺 Care:
-
-
- Etc.

📍 (only if mentioned) Made in (put the flag emoji regarding the country mentioned at the beginning of the sentence)`,
  },
  [CategoryType.plantes]: {
    label: '🪴 Plantes',
    promptFr: `Retravaille les descriptions de ces produits en suivant le template ci-dessous. Si tu ne trouves pas l'information, n'invente rien. Ne répète pas plusieurs fois les mêmes informations. N'utilise pas les "nous", "nos", "notre". Corrige les fautes d'orthographe si besoin.

🪴 Nom de la plante
    
✨ Phrase d’intro 1
    
🤍 Phrase d’intro 2
    
⚠️ Cache pot non inclus. (si c’est le cas)
    
💦 Comment l’arroser ?
-
-
- Etc.
    
📐 Dimensions :`,
    promptEn: `Make the descriptions of these products in American English following the template below. If you can't find the information, don't make it up. Don't repeat the same information several times. Do not use "our" or "us". Corrects spelling mistakes if necessary.

🪴 Name of the plant

✨ Intro sentence 1

🤍 Intro sentence 2

⚠️ Planter not included. (if applicable)

💦 How to water it?
-
-
- Etc.

📐 Dimensions:`,
  },
};
